export default [
  "•••• •••• •••• ••••",
  "···· ···· ···· ····",
  "•••••••••",
  "·········",
  "••••",
  "····",
  "•••",
  "···",
];
