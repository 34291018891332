const styles = (assetBaseUrl: string) => {
  const imageBase = assetBaseUrl + "images/";

  return {
    "#googlePay": {
      height: "50px",
      margin: "5px 0",
    }
  };
};

export default styles;